@import "app/styles/variables";

* {
  box-sizing: border-box;

  &:focus {
    outline: 0;
  }

  a {
    cursor: pointer;
  }
}

.teams-main-wrap,
.team-page-layout {
  font-size: 14px;
  margin-top: 20px;
  margin-left: 24px;
  border-radius: 4px;
  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
}

.team-main-container {
  h2.title {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: $textColor;
  }

  p.description {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: $placeHolder;

    a {
      color: $titleColor;
      text-decoration: none;

      &:hover {
        color: $titleColor;
      }
    }
  }

  .p-checkbox-box.p-state-active {
    background: transparent;
  }

  .p-dropdown .p-dropdown-trigger {
    position: relative;
  }

  .input-wrap {
    .p-inputtext {
      font-size: 16px;
      line-height: 18px;
      padding: 0 0 0 10px;
    }

    span.p-inputtext {
      border: unset;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
    }

    .p-dropdown.p-disabled {
      opacity: unset;
    }
  }

  .p-dropdown .p-dropdown-label {
    padding: 7px 18px 0 9px;
  }

  .input-primary.sm.w-90 .p-dropdown {
    max-width: 90px;

    .p-dropdown-label {
      min-width: 70px;
    }
  }
}

.no-padding {
  padding: 0 !important;
}

.no-margins {
  margin: 0 !important;
}

.col-5 {
  width: 41.6667% !important;
}

//Custom checkbox
.checkbox-wrap {
  display: flex;
  align-items: center;
  position: relative;
  pointer-events: auto;

  .label {
    vertical-align: middle;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: $textColor;
    margin-left: 10px;
  }

  .checkbox {
    height: 24px;
    width: 24px;
    border-radius: 6px;
    border: 1px solid #E3E3E3;

    input {
      height: 24px;
      width: 30%;
      position: absolute;
      opacity: 0;
      z-index: 10;

      &:hover {
        cursor: pointer;
      }
    }

    .checkmark {
      background: url('^../../assets/images/checkbox-mark.svg') no-repeat center;
      position: absolute;
      height: 23px;
      width: 23px;
      left: 0;
      z-index: 9;
    }
  }
}

.disable-events {
  pointer-events: none;
}

.disable-overlay {
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 105%;
  height: 125%;
  z-index: 100;
}

button.blue-btn {
  width: 100%;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $white;
  background-color: $activeColor;
  border: unset;
  border-radius: 6px;
  text-transform: uppercase;

  &:hover:enabled button {
    box-shadow: 0 0 8px rgba(60, 60, 60, 0.6);
  }

  &:disabled {
    cursor: auto;
    background: $placeHolder;
  }
}

.radio-btn {
  position: relative;

  .p-radiobutton {
    &.custom-radio {
      margin-bottom: 5px;
    }

    &.lg {
      .p-radiobutton-box {
        &.p-state-default {
          width: 1.3em;
          height: 1.3em;
          border-color: $headerTextColor;
        }

        &.p-state-active {
          color: #20a0a5;

          .p-radiobutton-icon {
            top: 47%;
            @media (max-width: 650px) {
              top: 46%;
            }
          }

          .p-radiobutton-icon:before {
            font-size: 18px;
            background: #20a0a5;
            border-radius: 50%;
          }
        }
      }
    }
  }

  & label.p-radiobutton-label,
  & label {
    font-weight: bold;
    font-size: 18px;
    color: $textColor;
    margin-left: 10px;
  }
}

p-multiselect {
  .multiSelect.p-multiselect {
    background-color: transparent;
    color: white;
    width: 150px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.radioButtons {
  .form-radio-button.p-radiobutton {
    position: absolute;
    bottom: 0;
  }

  .radio-btn label.p-radiobutton-label,
  .radio-btn label {
    padding-left: 20px;
    display: block;
  }
}

.scrollable {
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background: rgb(0, 0, 0);
    border: 4px solid transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #c4c4c4;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(
                    180deg,
                    rgba(0, 16, 26, 1) 0%,
                    rgba(0, 77, 127, 1) 100%
    );
  }
}
