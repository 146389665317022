@import 'app/styles/variables';
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  //transition: all 0.4s ease-in-out;
  &.rounded-border {
    border-radius: 80px;
    border: 1px solid;
    padding: calc(13px * var(--scale-factor)) calc(5px * var(--scale-factor));
    //outline button styles
    &.outline {
      background-color: transparent;
      border-color: black;
      color: black;
      &.company, &.employee, &.basic{
        border-color: $color-primary;
        color: $color-primary;
        &:hover {
          cursor: pointer;
          background: rgba(82, 190, 190, 0.4);
        }
      }
      &.resume {
        border-color: #E23E4A;
        color: #E23E4A;
        &:hover {
          cursor: pointer;
          background: rgba(226, 62, 74, 0.4);
        }
      }
      &.job {
        border-color: #30BA57;
        color: #30BA57;
        &:hover {
          cursor: pointer;
          background: rgba(48, 186, 87, 0.4);
        }
      }
      &.service {
        border-color: #F5C928;
        color: #F5C928;
        &:hover {
          cursor: pointer;
          background: rgba(245, 201, 40, 0.4);
        }
      }
    }
    //end of outline button styles
    &:not(.outline) {
      background-size: 300% 100%;
      color: white;
      &.company, &.employee, &.basic{
        border-color: transparent;
        background: $color-primary;
        &:hover {
          cursor: pointer;
          background: rgba(37, 175, 181, 0.7)
        }
      }
      &.resume {
        border-color: transparent;
        background: #E23E4A;
        &:hover {
          cursor: pointer;
          background: rgba(226, 62, 74, 0.7)
        }
      }
      &.job {
        border-color: transparent;
        background: #30BA57;
        &:hover {
          cursor: pointer;
          background: rgba(48, 186, 87, 0.7)
        }
      }
      &.service {
        border-color: transparent;
        background: #F5C928;
        &:hover {
          cursor: pointer;
          background: rgba(245, 201, 40, 0.7);
        }
      }
    }
  }
  &.delete {
    border-radius: 10px;
    border: 1px solid #ff0000;
    background: transparent;
    color: #494949;
    height: 40px;
    margin: 5px 0;
    padding: 0 1em;
    &:hover {
      cursor: pointer;
      background: rgba(215, 196, 196, 0.5);
    }
  }

  //fonts
    &.small {
        font-size: calc(12px * var(--scale-factor));
    }
    &.medium {
        font-size: calc(14px * var(--scale-factor));
    }
    &.large {
        font-size: calc(16px * var(--scale-factor));
    }
}
