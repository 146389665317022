@import './../node_modules/primeng/resources/primeng.css';
@import './app/styles/theme01';
@import './app/styles/variables';
@import './app/styles/p-theme';
@import './app/styles/common';
@import './app/styles/forms';
@import './app/styles/teams-common';
@import './app/styles/buttons';

@import './app/styles/buttons-new';

@import './app/styles/shared/components/footer';

@import './../node_modules/ngx-sharebuttons/themes/circles';
@import './../node_modules/ngx-sharebuttons/themes/default';

@import './app/styles/app/pages/home';
@import './app/styles/app/pages/upload-video-steps.component';
@import './app/styles/app/pages/add-business';
@import './app/styles/app/pages/my-profile';

p-editor {
  .ql-image {
    display: none !important;
  }
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
  filter: alpha(opacity=20);
}
