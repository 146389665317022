@import '../../variables.scss';

.app-footer {
  background: #061A29;
  padding: 20px 0;

  .logo-social-link {
    ul {
      li {
        cursor: pointer;
        display: inline-block;
        margin-right: 15px;
      }
    }
  }

  .footer-logo {
    img {
      cursor: pointer;
      width: 118px;
    }
  }

  .web-links {
    ul {
      li {
        color: $footerMenuColor;
        font-weight: normal;

        a {
          text-decoration: none;
          color: $footerMenuColor;
        }

        span,
        a {
          &:hover {
            cursor: pointer;
            color: $titleColor;
          }
        }
      }
    }
  }

  .copyright-text {
    color: $footerMenuColor;
    font-size: 16px;
    font-weight: normal;
    padding-top: 10px;
    text-align: center;
  }
}
