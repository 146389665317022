@import "app/styles/variables";
.upload-video {
  .upload-h1 {
    color: $labelColor;
    padding: 25px 0 15px;

    &.color-white {
      color: $white;
    }
  }

  .upload-sub {
    .upload-h2 {
      color: $labelColor;
      padding-bottom: 25px;
    }

    .upload-h3 {
      color: $labelColor;
      padding-bottom: 5px;
    }

    .upload-para {
      color: $labelColor;
      padding-bottom: 10px;
      margin: 0 auto;

      div {
        padding-left: 13px;
        position: relative;
        @media (min-width: 320px) {
          padding-left: 0;
        }
        @media (min-width: 481px) {
          padding-left: 13px;
        }

        &::after {
          background-color: $labelColor;
          // content: '';
          height: 1px;
          left: 0;
          position: absolute;
          top: 11px;
          width: 7px;
        }
      }

      .fa {
        &.fa-info-circle {
          cursor: pointer;
        }
      }
    }

    .rules-text {
      font-size: 16px;
      font-weight: bold;
    }

    .file-upload {
      background-color: $bgFileUpload;
      border-radius: 7px;
      margin: 0;
      padding: 0;
      text-align: center;
      width: 100%;

      .fa {
        color: $white;
        font-size: 50px;
      }

      .file-accepted-format {
        color: $white;
        font-size: 12px;
        font-style: italic;
        font-weight: 500;
        letter-spacing: 0.1px;
        margin-top: 40px;
      }

      .p-button {
        &.p-state-default {
          background: transparent;
          border: 0;
          height: 100%;
          width: 100%;
        }

        .p-button-text {
          display: none;
        }

        .pi {
          //font-size: 50px;
          left: 50%;
          margin: 0;
          top: 25%;
        }
      }

      .p-button.p-fileupload-choose {
        padding: 1.5em 0.75em;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;

        .p-button-label,
        .p-button-icon {
          font-size: 1.25em;
        }

        .p-button-label {
          flex: unset;
          margin-right: 5px;
        }

        &:hover {
          border-radius: 7px;
        }

        @media (max-width: 767px) {
          padding: 1.2em 0;
        }

        @media (max-width: 480px) {
          padding: 0.9em 0;
        }
      }

      .p-message-wrapper {
        color: #fff;
        background: $stateErrorTextColor;
        position: relative;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        padding: 10px;

        .p-message-icon {
          margin-right: 3px;

          &:before {
            content: '\e989';
          }
        }

        .p-message-close.p-link {
          color: #fff;
          border: 1px solid #fff;
          border-radius: 50%;
          padding: 4px;
          font-size: 0.7em;
          width: 17px;
          height: 16px;
          @media screen and (max-width: 481px) {
            margin-left: 3px;
            width: 13px;
            height: 12px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .upload-video .upload-sub .file-upload {
    height: auto;
  }
}

.business-upload-video {
  .upload-sub {
    border-radius: 4px;

    .upload-h2 {
      color: $labelColor;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0.1px;
      padding-bottom: 25px;
    }

    .upload-h3 {
      color: $labelColor;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      padding-bottom: 15px;
    }

    .upload-para {
      color: $labelColor;
      font-size: 16px;
      padding: 0 0 10px;

      div {
        padding-left: 13px;
        position: relative;

        &::after {
          background-color: $labelColor;
          // content: '';
          height: 1px;
          left: 0;
          position: absolute;
          top: 11px;
          width: 7px;
        }
      }
    }

    .rules-text {
      font-size: 16px;
      font-weight: bold;
    }

    .file-upload {
      background-color: $bgFileUpload;
      border-radius: 8px;
      height: 140px;
      padding: 0;
      text-align: center;
      width: 100%;

      .fa {
        color: $white;
        font-size: 50px;
      }

      .file-para {
        color: $white;
        font-size: 13px;
        font-weight: bold;
        line-height: 1.43;
        margin-top: -61px;
      }

      .file-accepted-format {
        color: $white;
        font-size: 12px;
        font-style: italic;
        font-weight: 500;
        margin-top: 40px;
      }

      .p-button {
        &.p-state-default {
          background: transparent;
          border: 0;
          height: 100%;
          width: 100%;
        }

        .p-button-text {
          display: none;
        }

        .pi {
          font-size: 50px;
          left: 50%;
          margin: 0;
          top: 21%;
          transform: translate(-50%, 0);

          &.pi-plus {
            &::before {
              content: '\e934';
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .upload-video {
    .upload-h1 {
      font-size: 24px;
      padding: 0;
    }

    .upload-sub {
      .upload-h2 {
        font-size: 18px;
      }

      .file-upload {
        width: 100%;
      }
    }

    &.my-business-list {
      .align-center-content {
        margin-bottom: 20px;
      }
    }
  }
}

.new-card-main {
  margin: 15px;

  .card-dialog {
    max-width: 100%;
    width: 600px;
    z-index: 9999999999;
  }

  p-dialog {
    .p-dialog {
      border-radius: 10px;

      .p-dialog-titlebar {
        background: linear-gradient(to left, $secColor, $titleColor);
        border-radius: 10px 10px 0 0;
        color: $white;
        font-size: 22px;
        font-weight: 500;
        padding: 25px 15px;
        text-align: center;
        text-transform: uppercase;

        .p-dialog-titlebar-close {
          display: none;
        }
      }
    }

    .new-card-content {
      margin: 0 auto;
      padding: 15px 0;
      width: 70%;

      .form-field {
        input {
          &[type='text'],
          &[type='password'] {
            border: 0;
            border-bottom: 1px solid $inputFieldBorderColor;
          }

          &:enabled {
            &:hover {
              border-bottom: 1px solid $buttonDefaultBgColor;
            }
          }
        }

        p-calendar {
          .p-button {
            &.p-state-default {
              border-right: 0;
              border-top: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .new-card-main {
    .card-dialog {
      width: 100%;
    }
  }

  .referred-dialog-width {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .new-card-main {
    p-dialog {
      .new-card-content {
        width: 90%;
      }
    }
  }
}

.my-business-list {
  min-height: 80vh;

  &.upload-video {
    background-color: transparent;
  }

  .align-center-content {
    align-items: center;
  }

  .my-business {
    background-color: $white;
    border: solid 1px $inputFieldBorderColor;
    border-radius: 4px;
    margin-bottom: 25px;
    padding: 30px 25px 25px;

    &:hover {
      background-color: $tableRowHoverColor;
      cursor: pointer;
    }

    .head {
      color: $labelColor;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0.1px;
    }

    img {
      border: 2px solid $myBusinessBorder;
      border-radius: 4px;
      height: 60px;
      margin-right: 10px;
      width: 80px;
    }

    .para1 {
      color: $footerMenuColor;
      font-size: 14px;
      font-weight: 500;
    }

    .para2 {
      color: $labelColor;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .new-customer {
      background-color: $mybusinessNewCustomer;
      border-radius: 4px;
      color: $white;
      display: inline-block;
      padding: 5px 10px;
    }

    .new-review {
      background-color: $mybusinessReview;
      border-radius: 4px;
      color: $white;
      display: inline-block;
      padding: 5px 10px;
    }

    .logo-container {
      align-items: center;
      border: 2px solid #adadbd;
      border-radius: 4px;
      display: flex;
      height: 60px;
      justify-content: center;
      margin-right: 10px;
      width: 100px;

      img {
        align-self: center;
        border: 0;
        height: auto;
        margin-right: 0;
        max-height: 100%;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .business-upload-video {
    .upload-sub {
      .file-upload {
        width: 273px;
      }
    }
  }
}
